import React from "react"

import Layout from "../components/layout"
import Row from "../components/row"
import Card from "../components/card"
import SectionTitle from "../components/sectionTitle"
import Banner from "../components/banner"
import SEO from "../components/seo"

import l from "../components/layout.module.scss" // layout styles
import Hero from "../components/hero"

const AboutTemplate = ({ pageContext }) => {
  const { title, acf } = pageContext

  const { blocks, banner, ...hero } = acf.about_template

  return (
    <Layout>
      <SEO title={title} />
      <Hero data={hero} />

      <Row>
        <SectionTitle title={hero.blocks_title} />
        <div className={l.col1}>
          <div className={l.section_grid_3}>
            {blocks.map(item => (
              <Card key={item.title} data={item} />
            ))}
          </div>
        </div>
      </Row>
      <Row>
        <Banner data={banner} src="about_template_page" />
      </Row>
    </Layout>
  )
}

export default AboutTemplate
